import axios from 'axios';
import { createApp } from 'vue'
import { createStore } from 'vuex'

export default new  createStore({
	state () {
        return {
          count: 0,
          cart_item: null,
          data_login:null,
        }
      },
	getters: {
	},
	mutations: {
		increment (state) {
			state.count++	
		},
		decrement (state) {
			if(state.count > 0) {
				state.count--
			}
		},
    updatedQytCart(state,payload){
      state.data_login = JSON.parse(localStorage.getItem("data_login"));
      const datas = {
        itemId : payload.itemId,
        userId : state.data_login.user.id,
        qty : payload.qty,
      };
      axios.post(
        "/api/updateCart", datas
      ).then(response => {
        localStorage.getItem("cartCounted");
        axios.get("/api/cartCounter", {
          params: { id: state.data_login.user.id },
        }).then((response2) => {
          var counted = response2.data.item_counted;
          localStorage.setItem("cartCounted", counted);
        });
      });
    },
	},
    actions: {
        async loadCart (state) {
            state.data_login = JSON.parse(localStorage.getItem("data_login"))
            if (localStorage.getItem('LogedIn') !== null){
              await axios.get("/api/cartData", { params: { id: state.data_login.user.id } }).then(
                (response) => {
                  state.cart_item = response.data;
                  if (state.cart_item.length === 0) {
                    // this.isHaveItem = false;
                  } else {
                    state.cart_item = state.cart_item.map((product) => ({
                      id : product.item_id,
                      title: product.item.name,
                      size: "10oz",
                      type: product.item.type,
                      price: parseFloat(product.item.price),
                      quantity: parseFloat(product.quantity),
                    }));
                    // this.isHaveItem = true;
                  }
                //   console.log(state.cart_item);
                //     return state.cart_item;
                }
              );
            return state.cart_item
            }
            // return state.data_login.user.id
        }
      }
})
<template>
  <div>
    <nav
      :class="{
        'bg-white': checkRoute($route.path) === true,
        'border-b-[1px] border-b-[#81868B]': checkRoute($route.path) === false,
      }"
      class="z-[60] navbar top-[-8px] pt-[26px] navbar-expand-md fixed-top mt-[39px] md:mt-[41px] py-4 px-6 md:px-16 border-b-[1px] border-b-[white]/10 flex justify-between"
      id="nav"
    >
      <svg
        @click="toggleSidebarLeft"
        xmlns="http://www.w3.org/2000/svg"
        class="z-10 cursor-pointer"
        :fill="svgFillColor"
        width="26"
        height="20"
        viewBox="0 0 26 20"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M25.9163 10.0001C25.9163 9.30973 25.3567 8.75008 24.6663 8.75008H1.33301C0.642651 8.75008 0.0830078 9.30973 0.0830078 10.0001C0.0830078 10.6904 0.642651 11.2501 1.33301 11.2501H24.6663C25.3567 11.2501 25.9163 10.6904 25.9163 10.0001Z"
          :fill="svgFillColor"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M25.9163 1.66675C25.9163 0.976392 25.3567 0.416748 24.6663 0.416748H1.33301C0.642651 0.416748 0.0830078 0.976392 0.0830078 1.66675C0.0830078 2.3571 0.642651 2.91675 1.33301 2.91675H24.6663C25.3567 2.91675 25.9163 2.3571 25.9163 1.66675Z"
          :fill="svgFillColor"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M25.9163 18.3334C25.9163 17.6431 25.3567 17.0834 24.6663 17.0834H1.33301C0.642651 17.0834 0.0830078 17.6431 0.0830078 18.3334C0.0830078 19.0238 0.642651 19.5834 1.33301 19.5834H24.6663C25.3567 19.5834 25.9163 19.0238 25.9163 18.3334Z"
          :fill="svgFillColor"
        />
      </svg>
      <div class="absolute flex justify-center w-full left-0">
        <div class="group flex h-[40px] w-10 justify-center">
          <router-link to="/">
            <svg
              :fill="svgFillColor"
              width="18"
              height="39"
              viewBox="0 0 28 49"
              xmlns="http://www.w3.org/2000/svg"
              class="hidden group-hover:block"
            >
              <path
                d="M1.44756 49L0 20.7652L12.5318 0L15.7578 23.2551L28 37.1484L16.13 38.8913L1.44756 49Z"
              />
            </svg>
            <h1
              class="block logo-text transition ease-in-out bebas-neue-font text-[1.8rem] group-hover:hidden mt-[5px]"
              :class="{
                'text-[#191B1E] group-hover:text-[#191B1E]':
                  checkRoute($route.path) === true,
                'text-[white] group-hover:text-[white]':
                  checkRoute($route.path) === false,
              }"
            >
              ASIXTH
            </h1>
          </router-link>
        </div>
      </div>
      <div class="flex items-center relative z-10">
        <router-link
          class="hidden md:block w-[70px] hover:text-[#212529] font-catarell"
          to="/shop"
          :class="textColorPrimary"
        >
          Shop All
        </router-link>

        <a class="ml-2 md:ml-6" href="#" @click="toggleSidebar">
          <svg
            width="28"
            height="28"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M18.1294 13.3319C19.8396 12.3993 21 10.5852 21 8.5C21 5.46243 18.5376 3 15.5 3C12.4624 3 10 5.46243 10 8.5C10 10.5852 11.1604 12.3993 12.8706 13.3319C8.3441 14.4991 5 18.609 5 23.5V26.5C5 28.433 6.567 30 8.5 30H22.5C24.433 30 26 28.433 26 26.5V23.5C26 18.609 22.6559 14.4991 18.1294 13.3319ZM11 8.5C11 6.01472 13.0147 4 15.5 4C17.9853 4 20 6.01472 20 8.5C20 10.9853 17.9853 13 15.5 13C13.0147 13 11 10.9853 11 8.5ZM15.5 14C10.2533 14 6 18.2533 6 23.5V26.5C6 27.8807 7.11929 29 8.5 29H22.5C23.8807 29 25 27.8807 25 26.5V23.5C25 18.2533 20.7467 14 15.5 14Z"
              :fill="svgFillColor"
            />
          </svg>
        </a>
        <a
          class="ml-2 md:ml-6 relative"
          :class="{
            'hover:text-white': checkRoute($route.path) === false,
            'hover:text-[#191B1E]': checkRoute($route.path) === true,
          }"
          href="#"
          @click="
            toggleNavbar();
            checkingCart();
          "
        >
          <svg
            width="28"
            height="28"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M22.4948 9.72727C24.526 10.3628 26 12.2593 26 14.5V24.5C26 27.2614 23.7614 29.5 21 29.5H10C7.23858 29.5 5 27.2614 5 24.5V14.5C5 12.2593 6.47397 10.3628 8.50522 9.72727C8.64848 5.98768 11.7253 3 15.5 3C19.2747 3 22.3515 5.98768 22.4948 9.72727ZM9.51869 9.52287C9.67709 9.50774 9.83764 9.5 10 9.5H21C21.1624 9.5 21.3229 9.50774 21.4813 9.52287C21.2381 6.43222 18.6531 4 15.5 4C12.3469 4 9.76185 6.43222 9.51869 9.52287ZM25 14.5C25 12.2909 23.2091 10.5 21 10.5H10C7.79086 10.5 6 12.2909 6 14.5V24.5C6 26.7091 7.79086 28.5 10 28.5H21C23.2091 28.5 25 26.7091 25 24.5V14.5Z"
              :fill="svgFillColor"
            />
          </svg>
          <div
            class="font-catarell top-2 flex absolute"
            :class="{
              'text-white': checkRoute($route.path) === false,
              'text-grey': checkRoute($route.path) === true,
              'right-[11px]': overNine === false,
              'right-2': overNine === true,
            }"
          >
            <p class="text-[12px]">
              {{ itemLength }}
            </p>
            <span
              class="text-[7px]"
              :class="{
                block: overNine === true,
                hidden: overNine === false,
              }"
              >+</span
            >
          </div>
        </a>
      </div>
    </nav>

    <SidebarPopupLeftDetail
      :isOpen="isSidebarLeftDetailOpen"
      @toggle="toggleSidebarLeftDetail"
    >
      <div>
        <div
          class="absolute w-[80%] md:w-[75%] text-center top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white"
        >
          <h1
            class="text-xl md:text-2xl font-neuton font-medium"
            v-if="detailSelectedMenu.length && detailSelectedMenu[0].content"
          >
            {{ detailSelectedMenu[0].content[0].title }}
          </h1>
          <p
            class="text-sm md:text-md font-catarell mt-3"
            v-if="detailSelectedMenu.length && detailSelectedMenu[0].content"
            v-html="detailSelectedMenu[0].content[0].desc"
          ></p>

          <div class="mt-4">
            <Button :title="'Return back'" @toggle="hideDetailMenu" />
          </div>
        </div>
      </div>
    </SidebarPopupLeftDetail>

    <SidebarPopupLeft :isOpen="isSidebarLeftOpen" @toggle="toggleSidebarLeft">
      <div class="relative pb-20">
        <Accordion
          :items="itemsAccordion"
          @selectSubMenuAccordion="selectSubMenuAccordion"
        />
        <p
          v-for="(item, index) in listMenus"
          :key="index"
          class="font-semibold cursor-pointer flex justify-between text-lg items-center border-b-[1px] border-b-[white] font-neuton mt-4 mb-2"
          @click="selectSubMenu(item)"
        >
          {{ item.title }}
        </p>
        <div class="mt-10">
          <h1 class="font-neuton text-lg font-semibold">
            Search with &nbsp;<b class="font-averia">ASIXTH</b>
          </h1>
          <p class="font-catarell text-sm">
            Quickly find what you are looking for
          </p>
          <InputFormAutoComplete
            :dataSet="dataSet"
            :toggleSidebar="toggleSidebarLeft"
            :placeholder="'Search here'"
            :typeInput="'text'"
            :values="searchTerm"
            @changeInput="handleChangeInputSearch"
          />
        </div>
      </div>
    </SidebarPopupLeft>

    <SidebarPopupLogin
      v-if="isLogin"
      :isOpen="isSidebarOpen"
      @toggle="toggleSidebar"
    >
      <div>
        <h1 class="font-neuton text-2xl md:text-[2vw] font-medium mb-10">
          Hello, {{ userName }}<br />
        </h1>
        <router-link
          to="/profile"
          @click="toggleSidebar"
          class="font-catarell text-md md:text-[1.1vw] text-white hover:underline"
        >
          View My Account
        </router-link>
        <br />
        <span
          @click="removedLoginState"
          class="font-catarell text-md md:text-[1.1vw] text-white hover:underline hover:cursor-pointer"
        >
          Logout
        </span>
      </div>
    </SidebarPopupLogin>

    <SidebarPopup v-else :isOpen="isSidebarOpen" @toggle="toggleSidebar">
      <div>
        <LoginForm v-if="isAuthForm === 'login'" @showForm="showForm" />
        <RegisterForm v-if="isAuthForm === 'register'" @showForm="showForm" />
      </div>
    </SidebarPopup>

    <NavbarPopup :isOpen="isNavbarOpen" @toggle="toggleNavbar">
      <div v-if="isHaveItem">
        <div
          class="md:w-[93%] w-[82%] fixed no-scrollbar overflow-y-scroll h-[45%] md:h-[40%]"
        >
          <table class="w-full">
            <thead
              class="text-white font-averia text-md md:text-2xl font-light"
            >
              <th class="py-4">Cart</th>
              <th class="w-1/4">Size</th>
              <th>Quantity</th>
              <th>Total</th>
            </thead>
            <tbody class="text-sm md:text-lg">
              <tr
                v-for="(item, index) in cartItem"
                :key="index"
                class="border-y-[1px] border-y-white font-averia"
              >
                <td
                  class="py-4 w-[31%] px-2 md:px-0 md:w-auto leading-6 md:leading-9 text-xs md:text-lg"
                >
                  {{ item.type }}{{ item.title.replace("Series", "") }} - ${{
                    item.price
                  }}
                  <br />
                </td>
                <td class="py-4 text-[1.2rem] px-2 md:px-0 text-xs md:text-lg">
                  {{ item.size }}
                </td>
                <!-- <td class="py-4 text-[1.2rem]">{{ item.quantity }}</td> -->
                <td class="py-4 text-[1.2rem] px-2 md:px-0 text-xs md:text-lg">
                  <InputWithDropdown
                    typeInput="number"
                    :values="item.quantity"
                    :itemId="item.id"
                    :checkingCart="checkingCart"
                  />
                </td>
                <td class="py-4 text-[1.2rem] px-2 md:px-0 text-xs md:text-lg">
                  ${{ item.price * item.quantity }}
                </td>
              </tr>
            </tbody>
            <br />
          </table>
        </div>
        <div class="fixed bottom-8 w-[82%] md:w-[93%]">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 pt-2">
            <div>
              <p
                class="font-catarell text-white text-[14px] md:text-lg mt-5 md:mt-4 hidden md:block"
              >
                We do not ship outside of the United States at this time. <br />
                Unfortunately we are not able to ship to Guam or APO
              </p>
              <p
                class="font-catarell text-white text-[14px] md:text-lg mt-5 md:mt-4 block md:hidden"
              >
                We do not ship outside of the United States at this time.
                Unfortunately we are not able to ship to Guam or APO
              </p>
              <p class="font-catarell text-white text-[14px] md:text-lg mt-4">
                All prices are listed in USD.
              </p>
            </div>
            <div>
              <div class="flex justify-between items-center">
                <p
                  class="font-catarell font-bold text-white text-xl md:text-xl mt-4"
                >
                  Subtotal
                </p>

                <p
                  class="font-catarell font-bold text-white text-xl md:text-2xl mt-4"
                >
                  ${{
                    cartItem
                      .reduce(
                        (total, item) => total + item.price * item.quantity,
                        0
                      )
                      .toFixed(2)
                  }}
                </p>
              </div>
              <button
                class="bg-white w-full mt-4 font-catarell font-bold text-black px-12 py-3 text-md md:text-xl"
                @click="checkOut()"
              >
                Checkout
              </button>
              <div class="justify-between items-center mt-3">
                <div class="text-center text-white font-catarell">
                  Shipping and Taxes Calculated at Checkout
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <p class="font-catarell text-white text-md md:text-xl mt-4">
          You have no Item in your cart, Please add one item to checkout
        </p>
      </div>
    </NavbarPopup>
  </div>
</template>

<script>
import SidebarPopup from "@/components/Popup/Sidebar.vue";
import SidebarPopupLogin from "@/components/Popup/SidebarLogin.vue";
import SidebarPopupLeft from "@/components/Popup/SidebarLeft.vue";
import SidebarPopupLeftDetail from "@/components/Popup/SidebarLeftPopup.vue";
import NavbarPopup from "@/components/Popup/Navbar.vue";
import LoginForm from "@/components/Auth/Login.vue";
import RegisterForm from "@/components/Auth/Register.vue";
import HamburgerIcon from "@/assets/icon/Menu.svg";
import Accordion from "@/components/Accordion/AccordionMenu.vue";
import InputFormAutoComplete from "@/components/Form/InputAutoCompleteV3.vue";
import InputWithDropdown from "@/components/Form/InputWithDropdown.vue";
import Button from "@/components/Button/Button.vue";
import Axios from "axios";
import DummyProduct from "@/assets/img/product/dummy.png";

export default {
  props: {
    sideBarLogin: Boolean,
    FunctionPopUpLogin: Function,
  },
  components: {
    SidebarPopup,
    SidebarPopupLeft,
    SidebarPopupLeftDetail,
    SidebarPopupLogin,
    NavbarPopup,
    Button,
    LoginForm,
    RegisterForm,
    InputFormAutoComplete,
    Accordion,
    InputWithDropdown,
  },
  computed: {
    svgFillColor() {
      if (
        this.$route.path === "/shop" ||
        this.$route.path === "/shop/canceled" ||
        this.$route.path === "/shop/success"
      ) {
        return "#191B1E";
      } else {
        return "white";
      }
    },
    textColorPrimary() {
      if (
        this.$route.path === "/shop" ||
        this.$route.path === "/shop/canceled" ||
        this.$route.path === "/shop/success"
      ) {
        return "text-[#191B1E]";
      } else {
        return "text-white";
      }
    },
  },
  mounted() {
    setTimeout(() => {
      window.addEventListener("scroll", this.handleScroll);
      this.isLoginCheck();
      this.gettingCartCounted();
      this.constantCheck();
      setTimeout(() => {
        if (this.$route.path === "/shop/canceled") {
          this.toggleNavbar();
          this.checkingCart();
        }
      }, 300);
    }, 450);
  },
  data() {
    return {
      logoColor: false,
      HamburgerIcon: HamburgerIcon,
      isHaveItem: false,
      isSidebarOpen: false,
      isSidebarLeftOpen: false,
      isSidebarLeftDetailOpen: false,
      isNavbarOpen: false,
      isLogin: false,
      isAuthForm: "login",
      searchTerm: "",
      itemLength: 0,
      overNine: false,
      userName: "test",
      detailSelectedMenu: [],
      isLogoHover: false,
      gray: false,
      dataSet: [
        {
          id: 1,
          title: "Massachusetts",
          value: "Massachusetts",
        },
        {
          id: 2,
          title: "Maryland",
          value: "Maryland",
        },
      ],
      itemsAccordion: [
        {
          title: "Shop",
          content: [
            {
              title: "Limited Release",
              link: "/product/show/Limited Release",
            },
            {
              title: "Red Label",
              link: "/product/show/Red Label",
            },
            {
              title: "White Label",
              link: "/product/show/White Label",
            },
            {
              title: "Black Label",
              link: "/product/show/Black Label",
            },
            {
              title: "Shop All",
              link: "/shop",
            },
          ],
          open: false,
        },
      ],
      listMenus: [
        {
          title: "About",
          link: "about",
          content: [
            {
              title: "About Us",
              desc: "ASIXTH is building the new standard for quality in the coffee space. <br>The name blends A + SIXTH together. Sixth being the extra sense we never really acknowledge but it’s always there <br><br>We are making it easier for people to differentiate high quality coffee and in doing so we create a world where coffee becomes an extraordinary experience <br><br>We have a meticulous 12% selection process, blind-graded 120 times globally by our expert coffee sommelier’s called Q graders <br><br>Our consumer mark “True Specialty” allows a faster recognition for quality and assurance that your coffee has been: <br><br>Graded Meticulously <br>Sourced Intentionally <br>Roasted Precisely <br>Farmed Sustainably <br>Priced Remarkably<br><br>Our foundational principles is based upon honesty, remarkable, and uncompromising in all that we do. We are more than just coffee, we are creating experiences that allows you to convey the invisible senses you are unaware of when you are at your best",
            },
          ],
        },
        {
          title: "Edition",
          link: "edition",
          content: [
            {
              title: "Coming Soon",
              desc: "",
            },
          ],
        },
        {
          title: "Autonomy",
          link: "autonomy",
          content: [
            {
              title: "Cultivating Typology",
              desc: "Typology allows you to determine what coffee region, roast style, price, brew, and more for a unique coffee experience both inside and outside our brand",
            },
          ],
        },
        {
          title: "Faces",
          link: "faces",
          content: [
            {
              title: "True Specialty Coffee",
              desc: "True Specialty is a consumer quality mark that evaluates and verifies that the coffee has achieved either Cup of Excellence status or equivalent.<br><br> True Specialty Coffee is coffee that has been graded meticulously, sourced intentionally, farmed sustainably, roasted precisely, and priced affordably ",
            },
          ],
        },
      ],
      cartItem: [],
      data_login: {},
    };
  },
  watch: {
    isSidebarOpen(newVal) {
      if (newVal) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }
    },
  },
  updated() {
    if (this.sideBarLogin == true) {
      this.toggleSidebar();
      this.FunctionPopUpLogin();
    }
  },
  methods: {
    checkingCart() {
      let item = this.$store.dispatch("loadCart").then((res) => {
        this.cartItem = res;
        if (localStorage.getItem("LogedIn") === "true") {
          var userId = this.data_login.user.id;
          if (this.cartItem.length === 0) {
            this.isHaveItem = false;
          } else {
            this.isHaveItem = true;
          }
        } else {
          this.isHaveItem = false;
        }
      });
    },
    checkOut() {
      const datas = {
        userId: this.data_login.user.id,
        Image: DummyProduct,
      };
      Axios.post("/api/checkOut", datas).then((response) => {
        location.replace(response.data.url);
      });
    },
    isLoginCheck() {
      var status = "";
      if (localStorage.getItem("LogedIn") == null) {
        status = false;
      } else {
        status = localStorage.getItem("LogedIn");
        this.data_login = JSON.parse(localStorage.getItem("data_login"));
      }
      if (status != null && status == "true") {
        this.isLogin = true;
        this.userName = this.data_login.user.first_name;
      }
    },
    toggleNavbar() {
      this.isNavbarOpen = !this.isNavbarOpen;
    },
    toggleSidebar() {
      this.isSidebarOpen = !this.isSidebarOpen;
    },
    toggleSidebarLeft() {
      this.isSidebarLeftOpen = !this.isSidebarLeftOpen;
    },
    toggleSidebarLeftDetail() {
      this.isSidebarLeftDetailOpen = !this.isSidebarLeftDetailOpen;
    },
    showForm(value) {
      this.isAuthForm = value;
    },
    handleChangeInputSearch(e) {
      this.searchTerm = e;
    },
    selectSubMenuAccordion() {
      this.isSidebarLeftOpen = false;
    },
    selectSubMenu(value) {
      // this.detailSelectedMenu = [value];
      // this.isSidebarLeftDetailOpen = true;
      // console.log(value.link);
      this.$router.push('/'+value.link);
      this.isSidebarLeftOpen = false;
    },
    hideDetailMenu() {
      this.isSidebarLeftDetailOpen = false;
      this.isSidebarLeftOpen = true;
    },
    beforeDestroy() {
      window.removeEventListener("scroll", this.handleScroll);
    },
    handleScroll() {
      const scrollPosition = window.scrollY;
      const navbar = document.getElementById("nav");
      if (scrollPosition > 0) {
        navbar.classList.add("bg-black", "bg-opacity-10", "backdrop-blur-xl");
      } else {
        navbar.classList.remove(
          "bg-black",
          "bg-opacity-10",
          "backdrop-blur-xl"
        );
      }
    },
    toggleLogo() {
      this.isLogoHover = !this.isLogoHover;
    },
    removedLoginState() {
      if (localStorage.getItem("LogedIn") != null) {
        localStorage.removeItem("LogedIn");
      }
      if (localStorage.getItem("data_login") != null) {
        localStorage.removeItem("data_login");
      }
      if (localStorage.getItem("cartCounted") != null) {
        localStorage.removeItem("cartCounted");
      }
      location.replace("/");
    },
    gettingCartCounted() {
      localStorage.removeItem("cartCounted");
      if (
        localStorage.getItem("LogedIn") != null &&
        localStorage.getItem("data_login") != null &&
        localStorage.getItem("cartCounted") == null
      ) {
        Axios.get("/api/cartCounter", {
          params: { id: this.data_login.user.id },
        }).then((response) => {
          var counted = response.data.item_counted;
          localStorage.setItem("cartCounted", counted);
          if (counted > 9) {
            this.itemLength = 9;
            this.overNine = true;
          } else {
            this.itemLength = localStorage.getItem("cartCounted");
            this.overNine = false;
          }
        });
      }
    },
    constantCheck() {
      var intervalid1 = setInterval(() => {
        if (
          localStorage.getItem("LogedIn") != null &&
          localStorage.getItem("data_login") != null &&
          localStorage.getItem("cartCounted") != null
        ) {
          var counted = localStorage.getItem("cartCounted");
          if (counted > 9) {
            this.itemLength = 9;
            this.overNine = true;
          } else {
            this.itemLength = localStorage.getItem("cartCounted");
            this.overNine = false;
          }
        }
      }, 2000);
    },
    checkRoute(data) {
      switch (data) {
        case "/shop":
          return true;
          break;
        case "/shop/success":
          return true;
          break;
        case "/shop/canceled":
          return true;
          break;
        default:
          return false;
          break;
      }
    },
  },
};
</script>

<style>
.navbar {
  transition: background-color 0.3s;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
</style>

<template>
  <div v-if="step === 1">
      <div>
        <h1 class="font-neuton text-2xl md:text-[2vw] font-normal">
          Login to  Your Account 
        </h1>
        <p class="font-catarell text-md md:text-[1.1vw] mt-4">
          We’ll send you a magic code to login in via phone, we currently only support USA/Canada area codes
        </p>

        <div className="mb-4 flex mt-4 ">
          <div className="w-[20%]">
            <Dropdown v-bind:country_code="flag_selected">
              <div className="py-2 px-4 flex items-center" @click="changeCountry('1')">
                <img :src="Flag1" class="mr-2" alt="">
                +1
              </div>
              <div className="py-2 px-4 flex items-center" @click="changeCountry('1')">
                <img :src="Flag2" class="mr-2" alt="">
                +1
              </div>
              <!-- <div className="py-2 px-4 flex items-center" @click="changeCountry('41')">
                <img :src="Flag3" class="mr-2" alt="">
                +41
              </div>
              <div className="py-2 px-4 flex items-center" @click="changeCountry('46')">
                <img :src="Flag4" class="mr-2" alt="">
                +46
              </div> -->
              <div className="py-2 px-4 flex items-center" @click="changeCountry('62')">
                <img :src="Flag5" class="mr-2" alt="">
                +62
              </div>
            </Dropdown>
          </div>

          <input 
            v-model="phoneNumber" 
            type="text" 
            class="w-[80%] col-span-2 text-md md:text-xl bg-transparent text-white border-y text-left border-r border-white outline-0 px-3 md:px-4 font-catarell placeholder:text-left" 
            placeholder="Enter Your Number"
          />

        </div>

        <Button :title="'Continue'" @toggle="requestOtp()"/>

        <!-- <p class="font-catarell text-md md:text-[1.1vw] mt-4">
            Dont have account ? <a @click="showForm('register')" class="font-bold" href="#">Register here</a>
        </p> -->
        <p class="font-neuton font-bold text-md md:text-[1.3vw] mt-4">
            What About a Password?
        </p>

        <p class="font-catarell text-md md:text-[1vw] leading-0 md:leading-7 mt-2 mb-10">
            Enter your phone number and we'll text you a magic code. Use a phone number you have access to securely.
        </p>

        <hr>

        <p class="font-neuton font-bold text-md md:text-[1.3vw] mt-4">
            Already Have  an Account ?
        </p>

        <p class="font-catarell text-md md:text-[1vw] leading-0 md:leading-7 mt-2 mb-10">
            Provide your phone number above and enter the magic code that we'll send to your device - you'll be logged into your new account.
        </p>
      </div>
  </div>
  <div v-else-if="step === 2">
    <OTPForm @toggle="nextStep"/>
  </div>
  <div v-else-if="step === 3">
    <AddNameForm @toggle="nextStep"/>
  </div>

</template>

<script>
import Button from '@/components/Button/Button.vue';
import Dropdown from '@/components/Form/Dropdown.vue';
import Flag1 from '@/assets/icon/Flags/USA.svg';
import Flag2 from '@/assets/icon/Flags/canada.svg';
import Flag3 from '@/assets/icon/Flags/3.svg';
import Flag4 from '@/assets/icon/Flags/4.svg';
import Flag5 from '@/assets/icon/Flags/5.svg';
import OTPForm from '@/components/Auth/OTP.vue';
import AddNameForm from '@/components/Auth/AddName.vue';
import Axios from 'axios';

export default {
  props: {
    title: String,
  },
  components: {
    Button,
    Dropdown,
    OTPForm,
    AddNameForm
  },
  data() {
    return {
      Flag1: Flag1,
      Flag2: Flag2,
      Flag3: Flag3,
      Flag4: Flag4,
      Flag5: Flag5,
      step: 1,
      phoneNumber: '',
      flag_selected : '+62',
      data_login: {},
    };
  },
  methods: {
    toggleSidebar() {
      this.$emit('toggle');
    },
    showForm(form) {
      this.$emit('showForm', form);
    },
    changeCountry(code){
      this.flag_selected = code;
    },
    nextStep() {
      this.step = this.step+1;
    },
    requestOtp(){
     const datas = {
       receiverNumber : `${this.flag_selected}${this.phoneNumber}` ,
       phoneNumber : this.phoneNumber,
       countryCode : this.flag_selected,
     };
     Axios.post(
        "/api/sendSMS", datas
      ).then(response => {
        this.data_login = response.data;
        // console.log(this.data_login);
        const passing_data_login =  this.data_login;
        var x = localStorage.setItem('data_login',JSON.stringify(passing_data_login));
        this.nextStep();  
      });
    },
  },
};
</script>

<style scoped></style>

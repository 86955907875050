//import vue router
import { createRouter, createWebHistory } from "vue-router";

//define a routes
const routes = [
  {
    path: "/",
    name: "home",
    component: () =>
      import(/* webpackChunkName: "home" */ "@/views/IndexWeb.vue"),
  },
  {
    path: "/complete",
    name: "complete.index",
    component: () =>
      import(/* webpackChunkName: "home" */ "@/views/complete/index.vue"),
  },
  {
    path: "/posts",
    name: "posts.index",
    component: () =>
      import(
        /* webpackChunkName: "post.index" */ "@/views/posts/PostIndex.vue"
      ),
  },
  {
    path: "/posts/create",
    name: "posts.create",
    component: () =>
      import(
        /* webpackChunkName: "post.create" */ "@/views/posts/PostCreate.vue"
      ),
  },
  {
    path: "/posts/edit/:id",
    name: "posts.edit",
    component: () =>
      import(/* webpackChunkName: "post.edit" */ "@/views/posts/PostEdit.vue"),
  },
  {
    path: "/shop",
    name: "shop.index",
    component: () =>
      import(/* webpackChunkName: "shop.index" */ "@/views/shop/index.vue"),
  },
  {
    path: "/shop/canceled",
    name: "shop.index.canceled",
    component: () =>
      import(/* webpackChunkName: "shop.index" */ "@/views/shop/index.vue"),
  },
  {
    path: "/shop/success",
    name: "shop.index.success",
    component: () =>
      import(/* webpackChunkName: "shop.index" */ "@/views/shop/index.vue"),
  },
  {
    path: "/product/detail/:id",
    name: "product.detail",
    component: () =>
      import(
        /* webpackChunkName: "product.index" */ "@/views/product/detail.vue"
      ),
  },
  {
    path: "/product/show/:type",
    name: "product.show",
    component: () =>
      import(
        /* webpackChunkName: "product.show" */ "@/views/product/three.vue"
      ),
  },
  {
    path: "/profile",
    name: "profile",
    component: () =>
      import(
        /* webpackChunkName: "profile.index" */ "@/views/profile/index.vue"
      ),
  },
  {
    path: "/addresses",
    name: "addresses",
    component: () =>
      import(
        /* webpackChunkName: "addresses.index" */ "@/views/addresses/index.vue"
      ),
  },
  {
    path: "/subscription",
    name: "subscription",
    component: () =>
      import(
        /* webpackChunkName: "subscription.index" */ "@/views/subscription/index.vue"
      ),
  },
  {
    path: "/history",
    name: "history",
    component: () =>
      import(
        /* webpackChunkName: "history.index" */ "@/views/history/index.vue"
      ),
  },
  {
    path: "/careers",
    name: "careers",
    component: () =>
      import(
        /* webpackChunkName: "careers.index" */ "@/views/careers/index.vue"
      ),
  },
  {
    path: "/contact",
    name: "contact",
    component: () =>
      import(
        /* webpackChunkName: "contact.index" */ "@/views/contact/index.vue"
      ),
  },
  {
    path: "/autonomy",
    name: "autonomy",
    component: () =>
      import(
        /* webpackChunkName: "autonomy.index" */ "@/views/autonomy/index.vue"
      ),
  },
  {
    path: "/search",
    name: "search",
    component: () =>
      import(/* webpackChunkName: "search.index" */ "@/views/search/index.vue"),
  },
  {
    path: "/faces",
    name: "faces",
    component: () =>
      import(/* webpackChunkName: "faces.index" */ "@/views/404/index.vue"),
  },
  {
    path: "/about",
    name: "about",
    component: () =>
      import(/* webpackChunkName: "faces.index" */ "@/views/about/index.vue"),
  },
  {
    path: "/edition",
    name: "edition",
    component: () =>
      import(/* webpackChunkName: "faces.index" */ "@/views/faces/index.vue"),
  },
  {
    path: "/:catchAll(.*)",
    name: "PageNotFound",
    component: () =>
      import(/* webpackChunkName: "notfound.create" */ "@/views/404/index.vue"),
  },
  {
    path: "/blog",
    name: "blog",
    component: () =>
      import(/* webpackChunkName: "blog.index" */ "@/views/404/index.vue"),
  },
  {
    path: "/blog/:id",
    name: "blog.detail",
    component: () =>
      import(/* webpackChunkName: "blog.index" */ "@/views/blog/detail.vue"),
  },
];

const router = createRouter({
  // base: '/my-app/',
  history: createWebHistory(),
  // mode: 'history',
  routes, // config routes
});

export default router;

<template>
  <div>
    <h1 class="font-neuton text-[1.65rem]">
      Confirm Your  Magic Code 
    </h1>
    <p class="font-catarell text-md md:text-[1.1vw] mt-3">
        <!-- We've texted a magic code to {{phoneNumber}} <br> -->
        Enter the magic code we sent to your phone to login or signup
    </p>

    <div class="mt-4">
      <v-otp-input
        ref="otpInput"
        v-model:value="VOtpInput"
        input-classes="otp-input"
        separator="-"
        :num-inputs="4"
        :should-auto-focus="true"
        input-type="letter-numeric"
        :conditionalClass="['one', 'two', 'three', 'four']"
        :placeholder="['*', '*', '*', '*',]"
        :class="{ 'error-border': otpError }"
      />
    </div>

    <p class="font-catarell text-[1rem] mt-4 mb-4">
      <p :class="{
        'text-[#CE5A67] font-catarell text-[1.1rem]' : otpMessageWrong == true
      }">{{otpMessage}}<a :class="{
        'text-[white] font-catarell text-[1rem]' : otpMessageWrong == true
      }"
      class="font-bold hover:text-[#E1DCC7] hover:cursor-default" @click="resendOTP">Send Again</a></p>
      {{otpMessage2}}
    </p>

    <!-- <Button :title="'Verify Now'" @toggle="toggleSidebar"/> -->
    <Button :title="'Verify Now'" @click="getDataLogin()"/>
  </div>
</template>

<script>
import Button from '@/components/Button/Button.vue';
import VOtpInput from "vue3-otp-input";
import Axios from 'axios';

export default {
  props: {
    title: String,
  },
  components: {
    Button,
    VOtpInput
  },
  data() {
    return {
      otpMessage: "Didn’t get the Code ? ",
      otpMessage2: "",
      otpMessageWrong: false,
      valOtp: "",
      // Use this is if your otp invalid
      otpError: false,
      data_login : {

      }
    };
  },
  watch: {
    // this.countdown_resend()
  },
  methods: {
    toggleSidebar() {
      this.$emit('toggle');
    },
    getDataLogin() {
      this.data_login = JSON.parse(localStorage.getItem('data_login'));
        const datas = {
          id : this.data_login.user.id
        };
      if (this.data_login.otp == this.VOtpInput) {
        if (this.data_login.user.first_name != null) {    
          Axios.post(
            "/api/logedIn", datas
          ).then(response => {
            this.data_login = response.data;
            const passing_data_login =  this.data_login;
            localStorage.removeItem('data_login');
            localStorage.setItem('data_login',JSON.stringify(passing_data_login));
            localStorage.setItem('LogedIn', true);
            location.reload();
          });

        } else{
          this.toggleSidebar();
        }
      } else{
        this.otpMessage = "You’ve enter wrong code "
        this.otpMessageWrong = true;
        this.otpError = true;
      }
    },
    resendOTP(){
      if (localStorage.getItem('resend_countdown') == null) {
        this.data_login = JSON.parse(localStorage.getItem('data_login'));
        const datas = {
          userId : this.data_login.user.id
        };
        Axios.post('api/resendOTP',datas).then(response => {
          localStorage.removeItem('data_login');
          this.data_login = response.data;
          localStorage.setItem('data_login',JSON.stringify(this.data_login))
          localStorage.setItem('resend_countdown', 120);
        });
      }else{
        var countdown = 0
        var msgCoundown = setInterval(()=>{
          countdown = parseInt(localStorage.getItem('resend_countdown'));
          //  countdown = countdown - 1;
          this.otpMessage2 = "You can resend the code after "+Math.floor(countdown/60)+' Minutes '+Math.round(countdown%60)+' Seconds';
        },1000);
      }
      this.countdown_resend();
    },
    countdown_resend(){
      var countdown  = 0;
        if (localStorage.getItem('counted') != 'true') {
          if (localStorage.getItem('resend_countdown') != null && parseInt(localStorage.getItem('resend_countdown')) > 0){
          var msgCoundown =  setInterval(() => {
                countdown = parseInt(localStorage.getItem('resend_countdown'));
                countdown = countdown - 1;
                localStorage.setItem('resend_countdown',countdown);
                // console.log('-1');
                if (countdown <= 0 || isNaN(countdown)) {
                  clearInterval(msgCoundown);
                  localStorage.removeItem('resend_countdown');
                  localStorage.removeItem('counted');
                }
                
            }, 1000)
            localStorage.setItem('counted',true);
          }
          
        } else{
          var msgCoundown = setInterval(()=>{
            countdown = parseInt(localStorage.getItem('resend_countdown'));
            //  countdown = countdown - 1;
            this.otpMessage2 = "You can resend the code after "+Math.floor(countdown/60)+' Minutes '+Math.round(countdown%60)+' Seconds';
          },1000);
        }
    },
    
  },
};
</script>

<style >
  .otp-input {
    width: 40px;
    height: 40px;
    padding: 5px;
    margin: 0 10px;
    font-size: 20px;
    border: 1px solid #FFF;
    text-align: center;
    color: #FFF;
    background-color: transparent;
  }

  .otp-input::-webkit-inner-spin-button,
  .otp-input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input::placeholder {
    font-size: 15px;
    text-align: center;
    font-weight: 600;
  }
  .error-border input {
    border: 2px solid #CE5A67;
  }
</style>

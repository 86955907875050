<template>
  <div class="at-display-form relative inline-block text-left relative">
    <input
      :type="typeInput"
      :value="values"
      :id="itemId"
      @mouseover="() => (isOpen = false)"
      @keyup="
      updateCart(itemId);"
      min="0"
      onkeypress="return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57"
      class="bg-transparent border-[1px] border-white py-3 px-4 w-[100%] md:w-full text-white placeholder:text-left placeholder:text-white placeholder:font-catarell placeholder:font-normal font-catarell outline-0"
    />
    <div class="absolute right-2 md:right-4 top-[3px] md:top-[7px]">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="cursor-pointer w-[18px] md:w-[24px] h-[22px] md:h-[25px]"
        width="30"
        height="25"
        viewBox="0 0 30 25"
        fill="none"
        @click="updateCartChevron(itemId, 'up')"
      >
        <path
          d="M21.4297 14.0811L14.9127 9.79562L7.96118 14.0811"
          stroke="#FFFEF2"
          stroke-width="1.8"
          stroke-linecap="round"
        />
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="cursor-pointer w-[18px] md:w-[24px] h-[22px] md:h-[25px]"
        width="30"
        height="25"
        viewBox="0 0 30 25"
        fill="none"
        @click="updateCartChevron(itemId, 'down')"
      >
        <path
          d="M8.57227 10.4072L15.0893 14.6927L22.0408 10.4072"
          stroke="#FFFEF2"
          stroke-width="1.8"
          stroke-linecap="round"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    typeInput: String,
    values: Number,
    itemId: Number,
    checkingCart: Function,
  },
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    toggleDropdown() {
      this.isOpen = !this.isOpen;
    },
    selectValue(newValue) {
      this.$emit("changeInput", newValue);
      this.isOpen = false;
    },
    testingevent(){
      // console.log('test');
    },
    updateCart(res) {
      if (document.getElementById(this.itemId).value !== null && document.getElementById(this.itemId).value !== '') {
           this.$store.commit("updatedQytCart", {
            itemId: this.itemId,
            qty: document.getElementById(this.itemId).value,
          });
          setTimeout(() => {  this.checkingCart();   }, 100);
      }
    },
    updateCartChevron(res, param) {
      if (param === "up") {
        document.getElementById(this.itemId).value = parseInt(document.getElementById(this.itemId).value) + 1;
        this.updateCart(res);
        // this.checkingCart();
      } else {
        document.getElementById(this.itemId).value = parseInt(document.getElementById(this.itemId).value) - 1;
        this.updateCart(res)
        // this.checkingCart();
      }
    },
  },
};
</script>

<style scoped></style>

<template>
  <div>
    <!-- Overlay -->
    <div v-if="isOpen" class="z-[70] fixed inset-0 bg-opacity-10 backdrop-blur-xl h-full cursor-pointer" @click="toggleSidebar"></div>

    <div :class="{ 'translate-x-0': isOpen,
     'translate-x-full': !isOpen}" class="z-[70] fixed h-full w-full md:w-full transition-transform duration-300 ease-in-out right-0">
      <div class="h-full text-white relative">
        <div :class="{
              'h-fit': quantity, 
              'h-full': !quantity}" class="bg-[#0D0D0E] bg-opacity-80 backdrop-blur-xl w-full h-fit relative" style="max-height: 100%;">
          <div class="pt-16 md:pt-36 px-10 md:px-16 z-[80] relative pb-10">
            <slot></slot>
          </div>
        </div>
        <button @click="toggleSidebar" class="fixed top-6 md:top-16 left-16 bg-transparent text-white rounded cursor-pointer">
          X
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isOpen: Boolean,
  },
  data(){
    return{
      quantity: false
    }
  },
  updated(){
    this.checkingCart();
    // console.log(this.quantity);
  },

  methods: {
    toggleSidebar() {
      this.$emit('toggle');
    },
    checkingCart(){
      setTimeout(() => {
        var intervalid1 = setInterval(() => {
          if (localStorage.getItem("cartCounted") == 0 || localStorage.getItem("cartCounted") == null) {
            this.quantity = true;
            clearInterval(intervalid1);
          }else{
            this.quantity = false;
            clearInterval(intervalid1);
          }
        }, 200);
      }, 150);
      
    }

  },
};
</script>

<style scoped>
.translate-x-0 {
  transform: translateY(0);
}

.translate-x-full {
  transform: translateY(-150%);
}
</style>

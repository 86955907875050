<template>
  <div>
    <h1 class="font-neuton text-2xl md:text-[2vw] font-normal">
      What Should We Call You?
    </h1>
    <p class="font-catarell text-md md:text-[1.1vw] mt-4">
        Enter your full name, first and last. We will address you by the name you provide for all ASIXTH matters
    </p>

    <input v-model="valName"  placeholder="Enter Your Name"
      type="text" 
      class="w-full mt-4 py-3 mb-4 md:py-4 text-md md:text-xl bg-transparent text-white border border-white outline-0 px-3 md:px-4 placeholder:text-left"
    />

    <!-- <Button :title="'Lets Go'" @toggle="toggleSidebar"/> -->
    <Button :title="'Lets Go'" @click="UpdateUserName()"/>
  </div>
</template>

<script>
import Button from '@/components/Button/Button.vue';
import Axios from 'axios';

export default {
  props: {
    title: String,
  },
  components: {
    Button
  },
  data() {
    return {
      valName: '',
      data_login : {

      }
    };
  },
  methods: {
    toggleSidebar() {
      this.$emit('toggle');
    },
    UpdateUserName(){
     var userData= JSON.parse(localStorage.getItem('data_login'))
     const datas = {
       Name : this.valName,
       UserId : userData.user.id,
     };
    //  console.log(datas);
     Axios.post(
        "/api/updateUserName", datas
      ).then(response => {
        this.data_login = response.data;
        // console.log(this.data_login);
        const passing_data_login =  this.data_login;
        localStorage.removeItem('data_login');
        localStorage.setItem('data_login',JSON.stringify(passing_data_login));
        localStorage.setItem('LogedIn', true);
        location.reload();
      });

    // this.toggleSidebar();
    }
  },
};
</script>

<style scoped></style>

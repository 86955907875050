<template>
  <div>
    <div v-if="step === 1">
      <h1 class="font-neuton text-2xl md:text-[2vw] font-normal">
        Create Your Account  
      </h1>
      <p class="font-catarell text-md md:text-[1.1vw] mt-4">
          We’ll send you magic code to login in via phone
      </p>


      <!-- <input v-model="phoneNumber" 
        type="number" 
        class="w-full mt-4 py-3 mb-4 md:py-4 text-md md:text-xl bg-transparent text-white border border-white outline-0 px-3 md:px-4"
      /> -->

        <div className="mb-4 flex mt-4 ">
          <div className="w-[20%]">
            <Dropdown v-bind:country_code="flag_selected">
              <div className="py-2 px-4 flex items-center" @click="changeCountry('1')">
                <img :src="Flag1" class="mr-2" alt="">
                +1
              </div>
              <div className="py-2 px-4 flex items-center" @click="changeCountry('255')">
                <img :src="Flag2" class="mr-2" alt="">
                +255
              </div>
              <div className="py-2 px-4 flex items-center" @click="changeCountry('41')">
                <img :src="Flag3" class="mr-2" alt="">
                +41
              </div>
              <div className="py-2 px-4 flex items-center" @click="changeCountry('46')">
                <img :src="Flag4" class="mr-2" alt="">
                +46
              </div>
              <div className="py-2 px-4 flex items-center" @click="changeCountry('62')">
                <img :src="Flag5" class="mr-2" alt="">
                +62
              </div>
            </Dropdown>
          </div>

          <input v-model="phoneNumber" 
            type="text" 
            class="w-[80%] col-span-2 text-md md:text-xl bg-transparent text-white border-y border-r border-white outline-0 px-3 md:px-4 font-catarell"
          />
        </div>

      <Button :title="'Continue'" @click="requestOtp()"/>

      <p class="font-catarell text-md md:text-[1.1vw] mt-4">
          Already have an account ? <a @click="showForm('login')" class="font-bold" href="#">Login here</a>
      </p>

      <p class="font-neuton font-bold text-md md:text-[1.2vw] mt-4">
          What About a Password
      </p>

      <p class="font-catarell text-md md:text-[1vw] leading-0 md:leading-7 mt-2 mb-4">
          Enter your phone number and we'll text you a magic code. <br>
          Use a phone number you have access to securely.
      </p>

      <hr>

      <p class="font-neuton font-bold text-md md:text-[1.2vw] mt-4">
          Already have  an Account ?
      </p>

      <p class="font-catarell text-md md:text-[1vw] leading-0 md:leading-7 mt-2 mb-10">
          Provide your phone number above and enter the magic code that we'll send to your device- you'll be logged into your new account.
      </p>
    </div>
    <div v-else-if="step === 2">
      <OTPForm @toggle="nextStep"/>
    </div>
    <div v-else-if="step === 3">
      <AddNameForm @toggle="nextStep"/>
    </div>
  </div>
</template>

<script>
import Dropdown from '@/components/Form/Dropdown.vue';
import Flag1 from '@/assets/icon/Flags/1.svg';
import Flag2 from '@/assets/icon/Flags/2.svg';
import Flag3 from '@/assets/icon/Flags/3.svg';
import Flag4 from '@/assets/icon/Flags/4.svg';
import Flag5 from '@/assets/icon/Flags/5.svg';
import Axios from 'axios';
import Button from '@/components/Button/Button.vue';
import OTPForm from '@/components/Auth/OTP.vue';
import AddNameForm from '@/components/Auth/AddName.vue';
import VueEasySession from 'vue-easysession'
var session = VueEasySession.getInstance()
export default {
  props: {
    title: String,
  },
  components: {
    Button,
    OTPForm,
    AddNameForm,
    Dropdown
  },
  data() {
    return {
      Flag1: Flag1,
      Flag2: Flag2,
      Flag3: Flag3,
      Flag4: Flag4,
      Flag5: Flag5,
      phoneNumber: '0',
      flag_selected : '+62',
      step: 1,
      data_login: {},
    };
  },
  methods: {
    nextStep() {
      this.step = this.step+1;
    },
    showForm(form) {
      this.$emit('showForm', form);
    },
    changeCountry(code){
      this.flag_selected = code;
      // console.log(this.flag_selected);
    },
    requestOtp(){
     const datas = {
       receiverNumber : `${this.flag_selected}${this.phoneNumber}` ,
       phoneNumber : this.phoneNumber,
       countryCode : this.flag_selected,
     };
     Axios.post(
        "/api/sendSMS", datas
      ).then(response => {
        this.data_login = response.data;
        const passing_data_login =  this.data_login;
        var x = localStorage.setItem('data_login',JSON.stringify(passing_data_login));
        this.nextStep();  
      });
    },
  },
};
</script>

<style scoped></style>

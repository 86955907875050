<template>
  <div>
    <!-- <nav class="navbar navbar-expand-md navbar-dark fixed-top bg-dark">
      <div class="container">
        <a class="navbar-brand" href="#">EXPRESS.JS + VUE.JS</a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse"
          aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarCollapse">
          <ul class="navbar-nav me-auto mb-2 mb-md-0">
            <li class="nav-item">
              <router-link :to="{ name: 'home' }" class="nav-link" aria-current="page">HOME</router-link>
            </li>
            <li class="nav-item">
              <router-link :to="{ name: 'posts.index' }" class="nav-link">POSTS</router-link>
            </li>
          </ul>
          <form class="d-flex">
            <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search">
            <button class="btn btn-success" type="submit">Search</button>
          </form>
        </div>
      </div>
    </nav> -->
    <WebNavbar :key="color" />
    <Navbar
      :sideBarLogin="sideBarLogin"
      :FunctionPopUpLogin="FunctionPopUpLogin"
    />
    <!-- <WebMenu /> -->

    <router-view :FunctionPopUpLogin="FunctionPopUpLogin"></router-view>
  </div>
</template>

<script>
import WebMenu from "./components/WebMenu.vue";
import WebNavbar from "./components/WebNavbar.vue";
import Navbar from "./components/Navbar/Navbar.vue";
import Axios from "axios";

export default {
  components: {
    WebNavbar,
    WebMenu,
    Navbar,
    // Pagination,
    // Navigation,
  },
  mounted() {
    if (
      localStorage.getItem("resend_countdown") != null &&
      parseInt(localStorage.getItem("resend_countdown")) > 0
    ) {
      var intervalId = window.setInterval(function () {
        var countdown = parseInt(localStorage.getItem("resend_countdown"));
        countdown = countdown - 1;
        localStorage.setItem("resend_countdown", countdown);
        // console.log("-1");
        if (countdown <= 0 || isNaN(countdown)) {
          clearInterval(intervalId);
          localStorage.removeItem("resend_countdown");
          localStorage.removeItem("counted");
        }
      }, 1000);
      localStorage.setItem("counted", true);
      if (parseInt(localStorage.getItem("resend_countdown")) <= 0) {
        localStorage.removeItem("counted");
      }
    }
    if (localStorage.getItem("data_login") != null) {
      if (localStorage.getItem("LogedIn") === null) {
        localStorage.removeItem("data_login");
      }
    }
    this.reUpdateDataLogin();
  },
  data() {
    return {
      sideBarLogin: false,
      temporaryData: {},
      temporaryData2: {},
    };
  },
  methods: {
    FunctionPopUpLogin() {
      if (this.sideBarLogin == true) {
        this.sideBarLogin = false;
      } else {
        this.sideBarLogin = true;
      }
    },
    reUpdateDataLogin() {
      if (localStorage.getItem("data_login") != null) {
        this.temporaryData = JSON.parse(localStorage.getItem("data_login"));
        Axios.get("/api/getDataUser", {
          params: { id: this.temporaryData.user.id },
        }).then((response) => {
          this.temporaryData2 = response.data;
          localStorage.removeItem("data_login");
          localStorage.setItem("data_login", JSON.stringify(this.temporaryData2));
          // console.log(localStorage.getItem("data_login"));
        });
      }
    },
  },
  updated() {},
};
</script>

<style>
body {
  background-color: rgb(43, 43, 43) !important;
}

.mt-custom {
  margin-top: 110px;
}
</style>

<template>
  <div>
    <!-- Overlay -->
    <div v-if="isOpen" class="z-[70] fixed inset-0 bg-black bg-opacity-10 backdrop-blur-xl cursor-pointer" @click="toggleSidebar"></div>

    <div :class="{ 'translate-x-0': isOpen, 'translate-x-full': !isOpen }" class="z-[70] fixed h-full w-full md:w-3/12 transition-transform duration-300 ease-in-out left-0">
      <div class="h-full text-white relative">
        <div class="bg-[#0D0D0E] overflow-y-scroll md:overflow-y-auto bg-opacity-70 backdrop-blur-xl w-full h-full absolute " style="max-height: 100%;">
          <div class="pt-16 md:pt-28 px-10 z-[80] ">
            <slot></slot>
          </div>
        </div>
        <button @click="toggleSidebar" class="fixed top-6 md:top-16 left-10 bg-transparent text-white rounded cursor-pointer">
          X
        </button>

        <div class="px-10 w-full fixed left-0 pb-4 bottom-0 bg-[#0D0D0E] bg-opacity-0 backdrop-blur-sm">
          <div class="flex justify-between border-t-[1px] border-t-white pt-2">
              <p class="font-catarell text-sm hover:cursor-pointer" @click="toggleSidebarRoute('contact')">Contact</p>
              <p class="font-catarell text-sm hover:cursor-pointer" @click="toggleSidebarRoute('careers')">Careers</p>
              <p class="font-catarell text-sm hover:cursor-pointer" @click="toggleSidebarRoute('404')">Locality</p>
              <p class="font-catarell text-sm hover:cursor-pointer" @click="toggleSidebarRoute('blog')">Journals</p>
              <p class="font-catarell text-sm hover:cursor-pointer" @click="toggleSidebarRoute('/')">Guide</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isOpen: Boolean,
  },
  methods: {
    toggleSidebarRoute(index) {
      this.$router.push('/'+index);
      this.$emit('toggle'); // Emit event toggle ketika tombol Toggle Sidebar ditekan
    },
    toggleSidebar() {
      this.$emit('toggle'); // Emit event toggle ketika tombol Toggle Sidebar ditekan
    },
  },
};
</script>

<style scoped>
.translate-x-0 {
  transform: translateX(0);
}

.translate-x-full {
  transform: translateX(-100%);
}
</style>
